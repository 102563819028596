/* @import url('https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&family=Roboto+Slab:wght@100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Anton+SC&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Noto+Sans+Glagolitic&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Exo+2:ital,wght@0,100..900;1,100..900&family=Monsieur+La+Doulaise&family=Noto+Sans+Glagolitic&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
    width: 200px;  /* Adjust this value to your preferred size */
    height: auto;  /* Maintain aspect ratio */
  }
  